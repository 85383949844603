import * as React from 'react';
import EIcon from './eIcon';
import Link from './link';
import clsx from 'clsx';
import FooterLogo from './footerLogo';
import { graphql, useStaticQuery } from 'gatsby';
import HeadingBlock from './headingBlock';
import { Formik } from 'formik';
import { FormField } from './formBlock';

const encode = data => Object.keys(data)
.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
.join("&");

const validateForm = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Invalid email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
}

const Footer = ({
  footerBackgroundClass = "color-scheme-one"
}) => {
  const footerSettings = useStaticQuery(graphql`
    query {
      sanityFooter {
        title
        terms
        submit
        placeholder
        showJaffle
        menuOne {
          title
          items {
            url
            noFollow
            newWindow
            label
          }
        }
        menuTwo {
          title
          items {
            url
            noFollow
            newWindow
            label
          }
        }
        menuThree {
          title
          items {
            url
            noFollow
            newWindow
            label
          }
        }
      }
    }
  `)

  const {
    title,
    terms,
    submit,
    placeholder,
    menuOne,
    menuTwo,
    menuThree,
    showJaffle
  } = footerSettings.sanityFooter;

  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [submitFail, setSubmitFail] = React.useState(false);
  const initialValues = {
    email: ''
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setTimeout(() => {
      setSubmitting(false);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "subscribe",
          ...values,
        }),
      })
        .then((response) => {
          setSubmitSuccess(true);
          resetForm(initialValues);
        })
        .catch((error) => {
          console.error(error);
          setSubmitFail(true);
        });
    }, 400);
  }

  return (
    <footer className={clsx("bg-[var(--color-scheme-background)] py-20 sm:py-24 lg:py-32 space-y-4", footerBackgroundClass)}
      aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <div className="contain mb-8">
        <div className='grid gap-x-8 gap-y-8 md:grid-cols-7'>
          <div className="col-span-2">
            <FooterLogo className='fill-[var(--color-scheme-foreground)] w-44 md:w-64' />            
          </div>
        </div>
      </div>

      <div className='w-full flex items-center h-20'>
        <div className="flex-1">
          <div className='h-0.5 bg-[color:var(--color-scheme-border)] w-full'></div>
        </div>
        <div className='max-w-[1400px] w-full flex items-center gap-x-4 pr-2 sm:pr-6 lg:pr-8'>
          <div className='h-0.5 bg-[color:var(--color-scheme-border)] w-full'></div>
          <EIcon className="fill-[color:var(--color-scheme-foreground)] h-16 md:h-24" />
        </div>
        <div className="flex-1"></div>
      </div>

      <div className="contain">
        <div className='grid gap-x-8 gap-y-8 lg:grid-cols-5 xl:grid-cols-7'>

          <div className='md:col-span-2 col-start-1'>
            <div className='flex flex-col gap-y-4 text-[color:var(--color-scheme-foreground)]'>
              <HeadingBlock headingType='h5' value={title} />
              <Formik initialValues={initialValues}
                validate={validateForm}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={onSubmit}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form className='flex flex-col'
                    data-netlify="true"
                    name="subscribe"
                    onSubmit={handleSubmit}
                    netlify-honeypot="bot-field">
                    <div className="hidden">
                      <label>
                        Don't fill this out if you're human:{" "}
                        <input name="bot-field" />
                      </label>
                    </div>
                    <div className={clsx('flex w-full relative', submitSuccess && 'hidden')}>
                      <label htmlFor="email-address" className='sr-only'>Email address</label>
                      <FormField fieldType='input'
                        name='email'
                        id='email-subscribe'
                        autoComplete="email"
                        placeholder={placeholder}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type='email' />
                      <div className="absolute h-full flex items-center justify-center right-6 top-0.5">
                        <button
                          type="submit"
                          className="font-heading italic text-sm"
                          aria-label="Submit">
                          {submit}
                        </button>
                      </div>
                    </div>
                    {(errors.email && touched.email) && (
                      <div className="mt-1 text-[color:var(--color-scheme-foreground)]">
                          {errors.email}
                      </div>
                    )}
                    <div className={clsx("h-full flex items-center", !submitSuccess && "hidden")}>
                      <p className="text-lg font-medium mb-4 text-center">
                        Thanks for subscribing!
                      </p>
                    </div>
                    <div className={clsx(!submitFail && "hidden")}>
                      <p className="text-lg font-medium mb-4 mt-2 text-center">
                        Something went wrong.
                      </p>
                    </div>
                  </form>
                )}
              </Formik>
              <p>{terms}</p>
            </div>
          </div>

          <div className='md:col-span-3 xl:col-span-5 grid md:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-8'>
            <div className='text-[color:var(--color-scheme-foreground)]'>
              <h3 className="font-heading text-2xl">{menuOne.title}</h3>
              <ul className="mt-4 space-y-2">
                {menuOne.items.map((i,k) => (
                  <li key={k}>
                    <Link to={i.url}
                      {...i}
                      className='hover:underline text-[color:var(--color-scheme-foreground)]'>
                      {i.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className='text-[color:var(--color-scheme-foreground)]'>
              <h3 className="font-heading text-2xl">{menuTwo.title}</h3>
              <ul className="mt-4 space-y-2">
                {menuTwo.items.map((i,k) => (
                  <li key={k}>
                    <Link to={i.url}
                      {...i}
                      className='hover:underline text-[color:var(--color-scheme-foreground)]'>
                      {i.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className='text-[color:var(--color-scheme-foreground)]'>
              <h3 className="font-heading text-2xl">{menuThree.title}</h3>
              <ul className="mt-4">
                {menuThree.items.map((i,k) => (
                  <li key={k}>
                    <Link to={i.url}
                      {...i}
                      className='hover:underline text-[color:var(--color-scheme-foreground)]'>
                      <span dangerouslySetInnerHTML={{__html: i.label}} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full flex items-center h-20'>
        <div className="flex-1"></div>
        <div className='contain w-full flex items-center gap-x-4'>
          
          <div className='grid gap-x-8 gap-y-8 md:grid-cols-7 w-full'>
            <div className='md:col-span-5 md:col-start-3'>
              <div className='h-0.5 bg-[color:var(--color-scheme-border)] w-full'></div>
            </div>
          </div>
        </div>
        <div className="max-sm:hidden flex-1">
          <div className='h-0.5 bg-[color:var(--color-scheme-border)] w-[calc(100%_+_0.5rem)] sm:w-[calc(100%_+_1.5rem)] lg:w-[calc(100%_+_2rem)] sm:-ml-6 lg:-ml-8'></div>
        </div>
      </div>

      <div className="contain">
        <div className='grid gap-x-8 gap-y-8 xl:grid-cols-7'>

          <div className='xl:col-span-5 xl:col-start-3'>
            <div className='flex flex-col lg:flex-row gap-4 md:items-center text-[color:var(--color-scheme-foreground)]'>
              <p>&copy; {new Date().getFullYear()} Écho Communication. All Rights Reserved.</p>
              
              <div>
                <Link to='/privacy-policy'
                  className='underline'>Privacy</Link>
              </div>
              {showJaffle && 
                <div class="flex-1 space-y-2">
                  <div className='text-center lg:text-end text-[color:var(--color-scheme-accent-two)] flex flex-wrap items-center md:justify-end justify-start gap-x-2 gap-y-1'>
                    <div>Made with love by the good people at </div>
                    <a href="https://www.jaffle.agency" rel="nofollow" className='rounded-full border border-[color:var(--color-scheme-foreground)] hover:bg-[var(--color-scheme-foreground)] hover:text-[color:var(--color-scheme-background)] transition-colors px-3 pt-0.5 pb-1 text-nowrap'>Jaffle Agency</a>
                  </div>
                  <div class="text-start md:text-center lg:text-end under">Brand Design by <a href="https://afom.com.au/" rel="nofollow" class="underline">A Friend of Mine</a></div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer;